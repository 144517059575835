<template>
  <a-row :gutter="48" layout="inline">
    <a-col :md="16" :sm="24">
      <a-form-item label="Felica 转换">
        <a-input v-model="felicaId" placeholder="16位 -> 20位 Access Code"/>
      </a-form-item>
    </a-col>
    <a-col :md="8" :sm="24">

      <span class="table-page-search-submitButtons" >
        <a-button type="primary" @click="convertFelica()">转换</a-button>
      </span>
    </a-col>
  </a-row>
</template>

<script>
import { convertFelica } from '@/api/aime'

export default {
  name: 'FelicaConvert',
  data () {
    return {
      felicaId: ''
    }
  },
  methods: {
    convertFelica () {
      convertFelica({ felicaId: this.felicaId }).then((res) => {
        this.felicaId = res
      })
    }
  }
}
</script>

<style scoped>

</style>
