<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6">
            <a-form-item label="搜索条件">
              <a-select v-model="queryType">
                <a-select-option value="accesscode">
                  卡号
                </a-select-option>
                <a-select-option value="id">
                  卡ID
                </a-select-option>
                <a-select-option value="aimeid">
                  AimeID(ExtID)
                </a-select-option>
                <a-select-option value="userid">
                  用户ID
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8">
            <a-form-item>
              <a-input v-model="query" placeholder="请输入搜索内容"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <span class="table-page-search-submitButtons" >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => this.queryParam = {}">重置</a-button>
            </span>
          </a-col>
          <a-col :md="12" :sm="24">
            <felica-convert/>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="loadData"
    >

      <span slot="webUserSlot" slot-scope="text, record">
        <router-link v-if="record.webUser" :to="{name: 'User List', query: {userId: record.webUser.id }}">{{ `UID: ${record.webUser.id} NAME: ${record.webUser.username}` }}</router-link>
      </span>
      <span slot="cards" slot-scope="text, record">
        <template>
          <template v-if="text.length === 0">没有卡片
            <a-divider/></template>
          <template v-if="text.length > 0" v-for="pCard in text">
            {{ pCard.luid }}
            <a-divider :key="pCard.id + 'divider'" type="vertical" />
            <a-popconfirm
              :key="pCard.id + 'confirm'"
              title="你确定要解绑该卡片吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleUnbind(record.id, pCard.luid)"
            >
              <a href="#">解绑</a>
            </a-popconfirm>
            <a-divider :key="pCard.id + 'divider3'" type="vertical" />
            <a-dropdown :key="pCard.id + 'ban'">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                Ban<a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleBan(pCard.luid)">Ban卡</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleUnban(pCard.luid)">解Ban卡</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-divider :key="pCard.id + 'divider2'"/>
          </template>

          <a @click="handleBind(record)">绑定</a>
        </template>
      </span>
    </s-table>

    <aime-bind-form
      ref="bindModal"
      :visible="bindFormVisible"
      :loading="confirmLoading"
      :model="formModel"
      @cancel="handleCancel"
      @ok="handleBindSave"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { banAime, bindAime, getAimeList, unbanAime, unbindAime } from '@/api/aime'
import AimeBindForm from '@/views/aime/modules/AimeBindForm'
import FelicaConvert from '@/components/FelicaConvert/FelicaConvert'

export default {
  name: 'AimeList',
  components: {
    FelicaConvert,
    AimeBindForm,
    STable
  },
  created () {
    this.routeUpdate(this.$route.query)
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.routeUpdate(to.query)
    this.$refs.table.refresh()
  },
  data () {
    return {
      bindFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryType: 'accesscode',
      query: '',
      loadData: parameter => {
        return getAimeList(Object.assign(parameter, { queryType: this.queryType, query: this.query }))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: 'AimeID',
          dataIndex: 'extId',
          sorter: true
        },
        {
          title: '卡号',
          dataIndex: 'physicalCards',
          scopedSlots: { customRender: 'cards' }
        },
        {
          title: '用户',
          dataIndex: 'webUser',
          scopedSlots: { customRender: 'webUserSlot' },
          sorter: false
        },
        {
          title: '注册时间',
          dataIndex: 'registerTime',
          sorter: true
        },
        {
          title: '访问时间',
          dataIndex: 'accessTime',
          sorter: true
        }
      ]
    }
  },
  methods: {
    routeUpdate (query) {
      if (query.id) {
        this.queryType = 'id'
        this.query = `${query.id}`
        return
      }
      if (query.userId) {
        this.queryType = 'userid'
        this.query = `${query.userId}`
        return
      }
      if (query.aimeId) {
        this.queryType = 'aimeid'
        this.query = `${query.aimeId}`
        return
      }
      if (query.accessCode) {
        this.queryType = 'accesscode'
        this.query = `${query.accessCode}`
        return
      }
      this.queryType = 'accesscode'
      this.query = ''
    },
    handleBind (record) {
      this.formModel = { id: record.id, accessCode: '' }
      this.bindFormVisible = true
    },
    handleCancel () {
      this.bindFormVisible = false
    },
    handleBindSave () {
      const form = this.$refs.bindModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          bindAime({ aimeId: values.id, accessCode: values.accessCode }).then((res) => {
            this.bindFormVisible = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success(res)
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUnbind (aimeId, accessCode) {
      unbindAime({ aimeId, accessCode }).then(() => {
        this.$refs.table.refresh()
      })
    },
    handleBan (accessCode) {
      banAime({ accessCode }).then()
    },
    handleUnban (accessCode) {
      unbanAime({ accessCode }).then()
    }
  }
}
</script>

<style scoped>

</style>
